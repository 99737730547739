.enregistrement-container {
  background-color: #dcb6c1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enregistrement-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px; /* Added max-width to limit form width on larger screens */
  width: 100%; /* Added width to ensure responsiveness */
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

button {
  padding: 10px 20px;
  background-color: #5e2ca5;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

button:hover {
  background-color: #873527;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .enregistrement-form {
    padding: 10px;
  }
  
  button {
    font-size: 14px;
  }
}
