.info-perso {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 897px;
  padding: 20px 25px;
  width: 390px;
}

.overlap-group-12 {
  height: 172px;
  position: relative;
  width: 320px;
}

.informations-personnelles-1 {
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 52px;
}

.ellipse-3-1 {
  cursor: pointer;
  height: 55px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 55px;
}

.flex-row-18 {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-left: 28px;
  margin-top: 79px;
  min-width: 223px;
}

.icon-user-1,
.icon-calendar-1,
.tel-1-1,
.icon-mail-1,
.icon-home-1 {
  height: 60px;
  width: 60px;
}

.nom-du-patient-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 147px;
}

.flex-row-19 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 16px;
  margin-right: 34.0px;
  margin-top: 25px;
  min-width: 250px;
}

.date-de-naissance {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 174px;
}

.flex-row-20 {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-left: 28px;
  margin-top: 25px;
  min-width: 227px;
}

.n-de-tlphone {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.03px;
  min-height: 25px;
  min-width: 151px;
}

.mail-container-1 {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-left: 28px;
  margin-top: 25px;
  min-width: 196px;
}

.adresse-mail-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 120px;
}

.flex-row-21 {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-left: 28px;
  margin-top: 25px;
  min-width: 151px;
}

.adresse {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 75px;
}

