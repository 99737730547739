.histo-traitement {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 891px;
  width: 390px;
}

.overlap-group-8 {
  align-items: flex-start;
  background-color: var(--romantic);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  min-height: 188px;
  padding: 12px 25px;
  width: 390px;
}

.overlap-group1-7 {
  height: 111px;
  margin-top: 8px;
  position: relative;
  width: 311px;
}

.title-7 {
  left: 29px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 51px;
}

.ellipse-5-5 {
  cursor: pointer;
  height: 55px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 55px;
}

.historique-2 {
  align-self: center;
  color: var(--black);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  min-height: 45px;
  min-width: 178px;
}

.resikali {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 50px;
  min-height: 25px;
}

.arrt-le-15022023 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 10px;
  min-height: 25px;
}

.neorecormon {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 56px;
  min-height: 25px;
}

.arrt-le-24012023 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 17px;
  min-height: 25px;
}

.un-alfa {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 49px;
  min-height: 25px;
}

.arrt-le-12112022 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 14px;
  min-height: 25px;
}

