.upload-btn {
  align-items: flex-end;
  background-color: #efb365;
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  height: 120px;
  justify-content: flex-end;
  margin-right: 1.0px;
  min-width: 120px;
  padding: 8px;
  cursor: pointer; /* ajout d'une propriété cursor pour indiquer que le bouton est cliquable */

  /* effet de hover */
  transition: background-color 0.2s ease-out; /* ajout d'une transition pour une animation en douceur */
}
.upload-btn:hover {
  background-color: var(--light-green); /* changement de couleur au survol */
}

.upload {
  height: 100px;
  width: 100px;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: var(--seashell-peach);
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
  max-height: 500px;
  overflow-y: auto;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-inner{
  display: flex;
  flex-direction: row;
}
.popup button {
  background-color: #888282;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;

}

.popup-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.popup-content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  width: 50%;
}

.popup-content > div button {
  padding: 10px 20px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.popup-content > div p {
  margin: 0;
}

.popup-image {
  margin-bottom: 20px;
  flex: 1;
  position: relative;
}

.popup-image img {
  width: 50px;
  height: auto;
}

.popup-content button {
  padding: 10px 20px;
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
