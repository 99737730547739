.profil-user {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 891px;
  padding: 20px 25px;
  width: 390px;
}

.flex-row-2 {
  align-items: flex-start;
  display: flex;
  gap: 53px;
  height: 112px;
  min-width: 232px;
}

.ellipse-3 {
  cursor: pointer;
  height: 55px;
  object-fit: cover;
  width: 55px;
}

.title-1 {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: normal;
  min-height: 60px;
  min-width: 124px;
}

.flex-row-3 {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-left: 20px;
  margin-top: 45px;
  min-width: 244px;
}

.icon-user,
.icon-calendar,
.tel-1,
.icon-mail,
.icon-home {
  height: 60px;
  width: 60px;
}

.franois-dupont {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 168px;
}

.flex-row-4 {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-left: 20px;
  margin-top: 25px;
  min-width: 179px;
}

.date {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 103px;
}

.flex-row-5 {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-left: 20px;
  margin-top: 25px;
  min-width: 205px;
}

.phone {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.03px;
  min-height: 25px;
  min-width: 129px;
}

.mail-container {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 16px;
  margin-right: 27.0px;
  margin-top: 25px;
  min-width: 273px;
}

.fdupontgmailcom {
  cursor: pointer;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 197px;
}

.flex-row-6 {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  height: 66px;
  margin-left: 20px;
  margin-top: 25px;
  min-width: 228px;
}

.address {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: normal;
  min-height: 50px;
  min-width: 152px;
}

.overlap-group-3 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: var(--pearl-bush);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 45px;
  margin-right: 22px;
  margin-top: 78px;
  min-width: 295px;
  padding: 9px 108px;
}

.modifier,
.changer-mot-de-passe {
  letter-spacing: 0;
  line-height: normal;
  min-height: 25px;
}

.overlap-group1-2 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: var(--pearl-bush);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 45px;
  margin-right: 22px;
  margin-top: 78px;
  min-width: 295px;
  padding: 9px 108px;
}

.edit_password {
  padding: 10px 20px;
  background-color: #a52c2c;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}