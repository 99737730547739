.urgence {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 891px;
  width: 390px;
}

.overlap-group1-5 {
  background-color: var(--contessa);
  border-radius: 2px;
  height: 188px;
  position: relative;
  width: 390px;
}

.numros-durgence-1 {
  left: 79px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 47px;
}

.ellipse-5-3 {
  cursor: pointer;
  height: 55px;
  left: 25px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 55px;
}

.flex-row-8 {
  align-items: center;
  display: flex;
  gap: 18px;
  margin-left: 44px;
  margin-top: 52px;
  min-width: 144px;
}

.overlap-group2-2 {
  align-items: flex-start;
  background-color: #32a6d4;
  border-radius: 35px;
  display: flex;
  height: 70px;
  min-width: 70px;
  padding: 9px 15px;
}

.number {
  letter-spacing: 0;
  line-height: normal;
  min-height: 50px;
}

.samu {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 56px;
}

.flex-row-9 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 18px;
  margin-right: 78.0px;
  margin-top: 22px;
  min-width: 224px;
}

.overlap-group4-2 {
  align-items: flex-start;
  background-color: #393868;
  border-radius: 35px;
  display: flex;
  height: 70px;
  min-width: 70px;
  padding: 9px 16px;
}

.police-secours {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 136px;
}

.flex-row-10 {
  align-items: center;
  display: flex;
  gap: 18px;
  margin-left: 44px;
  margin-top: 22px;
  min-width: 176px;
}

.overlap-group-6 {
  align-items: flex-start;
  background-color: #dc212f;
  border-radius: 35px;
  display: flex;
  height: 70px;
  justify-content: flex-end;
  min-width: 70px;
  padding: 9px 15px;
}

.pompiers {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 25px;
  min-width: 88px;
}

.flex-row-11 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 18px;
  margin-right: 23.0px;
  margin-top: 22px;
  min-width: 279px;
}

.overlap-group3-2 {
  align-items: flex-start;
  background-color: #e49021;
  border-radius: 35px;
  display: flex;
  height: 70px;
  min-width: 70px;
  padding: 9px 8px;
}

.numro-d-appel-d-urgence-europen {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.0px;
  min-height: 50px;
  min-width: 191px;
}

