:root { 
  --black: #000000;
  --black-2: #00000033;
  --contessa: #c1666c;
  --del-rio: #b09e99;
  --keppel: #48a9a6;
  --moon-mist: #dae1cb;
  --pearl-bush: #ebded4;
  --romantic: #fad1c0;
  --seashell-peach: #fff5ec;
  --white: #ffffff;
  --yellow-green: #bad381;
 
  --font-size-l: 32px;
  --font-size-m: 20px;
  --font-size-s: 16px;
  --font-size-xl: 36px;
  --font-size-xs: 14px;
  --font-size-xxl: 40px;
  --font-size-xxxl: 48px;
 
  --font-family-quicksand: "Quicksand", Helvetica;
}
.quicksand-medium-black-20px {
  color: var(--black);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.quicksand-bold-black-48px {
  color: var(--black);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.quicksand-medium-black-16px {
  color: var(--black);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.quicksand-bold-white-40px {
  color: var(--white);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.quicksand-medium-black-32px {
  color: var(--black);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.quicksand-bold-black-32px {
  color: var(--black);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
