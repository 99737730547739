.evolution {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 1331px;
  overflow: hidden;
  width: 389px;
}

.rectangle-7-1 {
  background-color: var(--del-rio);
  border-radius: 2px;
  height: 188px;
  /* left: 0; */
  position: fixed;
  top: 0;
  width: 390px;
  z-index: 9;
}

.ellipse-5 {
  cursor: pointer;
  height: 55px;
  /* left: 25px; */
  object-fit: cover;
  position: fixed;
  top: 20px;
  width: 55px;
  z-index: 10;
}

.title-3 {
  /* left: 88px; */
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  top: 71px;
  z-index: 11;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  text-align: center;
}

.cratinine {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 32px;
  margin-top: 214px;
  min-height: 25px;
  z-index: 1;
}

.graph1-1 {
  align-self: center;
  height: 200px;
  margin-right: 1.0px;
  margin-top: 11px;
  object-fit: cover;
  width: 320px;
  z-index: 5;
}

.potassium {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 32px;
  margin-top: 47px;
  min-height: 25px;
  z-index: 2;
}

.graph-2-1 {
  align-self: center;
  height: 201px;
  margin-left: 3.0px;
  margin-top: 11px;
  object-fit: cover;
  width: 320px;
  z-index: 6;
}

.calcmie {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 32px;
  margin-top: 43px;
  min-height: 25px;
  z-index: 3;
}

.graph3-1 {
  align-self: center;
  height: 200px;
  margin-left: 3.0px;
  margin-top: 16px;
  object-fit: cover;
  width: 320px;
  z-index: 7;
}

.hmoglobine {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 36px;
  margin-top: 48px;
  min-height: 25px;
  z-index: 4;
}

.graph4-1 {
  align-self: center;
  height: 200px;
  margin-right: 5.0px;
  margin-top: 11px;
  object-fit: cover;
  width: 320px;
  z-index: 8;
}

