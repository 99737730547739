.camera-container {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 75px none;
  display: flex;
  flex-direction: column;
  height: 891px;
  width: 390px;
}

video {
  width: 100%;
  max-width: 600px;
  margin-bottom: 10px;
}

.ellipse-5-2 {
  cursor: pointer;
  height: 50px;
  left: 25px;
  object-fit: cover;
  position: absolute;
  top: 75px;
  width: 50px;
}

.camera {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  width: 390px;
}

.image-1 {
  height: 844px;
  object-fit: cover;
  width: 390px;
}