.accueil {
  align-items: flex-end;
  background-color: var(--seashell-peach);
  border: 100px none;
  /* display: flex; */
  flex-direction: column;
  gap: 50px;
  height: 900px;
  padding: 25px;
  margin-top: auto;
  width: 390px;
}

.flex-row {
  align-items: flex-start;
  display: flex;
  height: 107px;
  min-width: 333px;
}

.icon-notifications {
  height: 40px;
  margin-top: 8px;
  object-fit: cover;
  width: 40px;
}

.place {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 44px;
  min-height: 100px;
  min-width: 158px;
}

.ellipse-1 {
  cursor: pointer;
  height: 55px;
  margin-left: 36px;
  object-fit: cover;
  width: 55px;
}

.flex-row-1 {
  align-items: flex-start;
  display: flex;
  gap: 50px;
  height: 566px;
  margin-right: 27px;
  min-width: 295px;
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 541px;
  position: relative;
  width: 139px;
}

.appareil-photo {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 9px;
  min-height: 25px;
  min-width: 139px;
}

.overlap-group2 {
  align-items: flex-end;
  background-color: var(--yellow-green);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  justify-content: flex-end;
  margin-right: 1.0px;
  margin-top: 40px;
  min-width: 120px;
  padding: 8px;
  cursor: pointer; /* ajout d'une propriété cursor pour indiquer que le bouton est cliquable */

  /* effet de hover */
  transition: background-color 0.2s ease-out; /* ajout d'une transition pour une animation en douceur */
}
.overlap-group2:hover {
  background-color: var(--light-green); /* changement de couleur au survol */
}


.icon-search,
.traitement-1 {
  height: 100px;
  width: 100px;
}

.camera-icon,
.camera {
  height: 100px;
  width: 100px;
}
.analyses {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 9px;
  min-height: 25px;
  min-width: 139px;
}

.overlap-group3 {
  align-items: flex-end;
  background-color: var(--romantic);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  justify-content: flex-end;
  margin-right: 1.0px;
  margin-top: 39px;
  min-width: 120px;
  padding: 8px;
  cursor: pointer; /* ajout d'une propriété cursor pour indiquer que le bouton est cliquable */

  /* effet de hover */
  transition: background-color 0.2s ease-out; /* ajout d'une transition pour une animation en douceur */
}
.overlap-group3:hover {
  background-color: var(--light-green); /* changement de couleur au survol */
}



.traitements {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 9px;
  min-height: 25px;
  min-width: 113px;
}

.flex-col-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 566px;
  width: 120px;
}

.overlap-group-1 {
  align-items: center;
  background-color: var(--keppel);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  min-width: 120px;
  padding: 0 12px;
  cursor: pointer; /* ajout d'une propriété cursor pour indiquer que le bouton est cliquable */

  /* effet de hover */
  transition: background-color 0.2s ease-out; /* ajout d'une transition pour une animation en douceur */
}
.overlap-group-1:hover {
  background-color: var(--light-green); /* changement de couleur au survol */
}


.photo3-1,
.tel-2 {
  height: 96px;
  width: 96px;
}

.fichiers {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 1.0px;
  margin-top: 9px;
  min-height: 25px;
  min-width: 71px;
}

.overlap-group1-1 {
  align-items: center;
  background-color: var(--del-rio);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  margin-top: 40px;
  min-width: 120px;
  padding: 0 10px;
  cursor: pointer; /* ajout d'une propriété cursor pour indiquer que le bouton est cliquable */

  /* effet de hover */
  transition: background-color 0.2s ease-out; /* ajout d'une transition pour une animation en douceur */
}
.overlap-group1-1:hover {
  background-color: var(--light-green); /* changement de couleur au survol */
}

.evolution-1 {
  height: 96px;
  width: 100px;
}

.evolutions {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 9px;
  min-height: 25px;
  min-width: 96px;
}

.overlap-group4 {
  align-items: flex-end;
  background-color: var(--contessa);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  justify-content: flex-end;
  margin-top: 39px;
  min-width: 120px;
  padding: 10px 11px;cursor: pointer; /* ajout d'une propriété cursor pour indiquer que le bouton est cliquable */

  /* effet de hover */
  transition: background-color 0.2s ease-out; /* ajout d'une transition pour une animation en douceur */
}
.overlap-group4:hover {
  background-color: var(--light-green); /* changement de couleur au survol */
}

.overlap-group1-2{
  align-items: flex-end;
  background-color: var(--contessa);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  justify-content: flex-end;
  margin-top: 39px;
  min-width: 120px;
  padding: 10px 11px;
  cursor: pointer; /* ajout d'une propriété cursor pour indiquer que le bouton est cliquable */

  /* effet de hover */
  transition: background-color 0.2s ease-out; /* ajout d'une transition pour une animation en douceur */
}
.overlap-group1-1:hover {
  background-color: var(--light-green); /* changement de couleur au survol */
}

.numros-durgence {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 10.0px;
  margin-top: 9px;
  min-height: 50px;
  min-width: 94px;
  text-align: center;
}

.custom-button:hover {
  background-color: #f0f0f0;
  color: #000;
}
