.modifier-profil {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 118px;
  height: 891px;
  padding: 20px 25px;
  width: 390px;
}

.flex-row-22 {
  align-items: flex-start;
  display: flex;
  gap: 7px;
  height: 571px;
  min-width: 338px;
}

.flex-col-3 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 557px;
  width: 80px;
}

.ellipse-3-2 {
  align-self: flex-start;
  cursor: pointer;
  height: 55px;
  object-fit: cover;
  width: 55px;
}

.icon-user-2 {
  height: 60px;
  margin-right: 0.03px;
  margin-top: 102px;
  width: 60px;
}

.icon {
  height: 60px;
  margin-right: 0.03px;
  margin-top: 25px;
  width: 60px;
}

.tel-1-2 {
  height: 60px;
  margin-top: 25px;
  width: 60px;
}

.flex-col-4 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 519px;
  width: 251px;
}

.title-8 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 21px;
  min-height: 60px;
}

.overlap-group1-11 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 44px;
  margin-top: 53px;
  min-width: 251px;
  padding: 8px 9px;
}

.franois-dupont-2,
.date-1,
.phone-1,
.enregistrer {
  letter-spacing: 0;
  line-height: normal;
  min-height: 25px;
}

.overlap-group2-5 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 44px;
  margin-top: 40px;
  min-width: 251px;
  padding: 9px;
}

.overlap-group4-5 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 44px;
  margin-top: 42px;
  min-width: 251px;
  padding: 8px 9px;
}

.overlap-group3-5 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 44px;
  margin-top: 41px;
  min-width: 251px;
  padding: 8px 9px;
}

.fdupontgmailcom-1 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: normal;
  min-height: 25px;
}

.overlap-group-13 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  height: 66px;
  margin-top: 41px;
  min-width: 251px;
  padding: 0 9px;
}

.address-5 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 50px;
  min-width: 152px;
}

.overlap-group5-1 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: var(--pearl-bush);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 45px;
  margin-right: 22px;
  min-width: 295px;
  padding: 9px 96px;
}

