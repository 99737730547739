.choixu95profil {
  align-items: center;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 844px;
  padding: 72px 47px;
  width: 390px;
}

.title {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  min-height: 60px;
  min-width: 239px;
  text-align: center;
}

.identifiant {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2px;
  margin-top: 92px;
  min-height: 25px;
  min-width: 96px;
  text-align: center;
}

.rectangle-3-1 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--black-2);
  height: 48px;
  margin-left: 1.0px;
  margin-top: 9px;
  width: 293px;
}

.mot-de-passe {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2px;
  margin-top: 42px;
  min-height: 25px;
  min-width: 126px;
  text-align: center;
}

.rectangle-4 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--black-2);
  height: 48px;
  margin-left: 1.0px;
  margin-top: 11px;
  width: 293px;
}

.mot-de-passe-oubli-1 {
  color: var(--black);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xs);
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 21px;
  min-height: 18px;
  min-width: 132px;
  text-align: center;
}

.overlap-group-2 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: var(--pearl-bush);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 45px;
  margin-top: 238px;
  min-width: 295px;
  padding: 9px 86px;
}

.se-connecter {
  letter-spacing: 0;
  line-height: normal;
  min-height: 25px;
  min-width: 123px;
  text-align: center;
}

