.liste-traitement2 {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 891px;
  width: 390px;
}

.overlap-group1-4 {
  background-color: var(--romantic);
  border-radius: 2px;
  height: 188px;
  position: relative;
  width: 390px;
}

.title-5 {
  left: 71px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 50px;
}

.ellipse-5-2 {
  cursor: pointer;
  height: 50px;
  left: 25px;
  object-fit: cover;
  position: absolute;
  top: 75px;
  width: 50px;
}

.kaleorid {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 50px;
  min-height: 25px;
}

.address-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 14px;
  min-height: 25px;
}

.liste-traitement2-item {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 52px;
  min-height: 25px;
}

.overlap-group-5 {
  align-self: flex-end;
  height: 69px;
  margin-right: 16px;
  margin-top: 81px;
  position: relative;
  width: 77px;
}

.historique {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 49px;
}

.ellipse-11-1 {
  cursor: pointer;
  height: 50px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 50px;
}