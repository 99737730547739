.analyse1 {
  align-items: center;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 891px;
  width: 390px;
}

.rectangle-7-2 {
  background-color: var(--yellow-green);
  border-radius: 2px;
  height: 188px;
  /* left: 0; */
  position: fixed;
  top: 0;
  width: 390px;
  z-index: 5;
}

.ellipse-5-1 {
  cursor: pointer;
  height: 55px;
  /* left: 25px; */
  object-fit: cover;
  position: fixed;
  top: 20px;
  width: 55px;
  z-index: 6;
}

.title-4 {
  /* left: 93px; */
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  top: 71px;
  z-index: 7;
}

.overlap-group1-3 {
  align-items: flex-start;
  background-color: var(--pearl-bush);
  border-radius: 20px;
  display: flex;
  height: 102px;
  justify-content: flex-end;
  margin-right: 36.0px;
  margin-top: 222px;
  min-width: 296px;
  padding: 10px 7px;
  z-index: 3;
}

.votre-possium-est-tr {
  letter-spacing: 0;
  line-height: normal;
  min-height: 80px;
  width: 281px;
}

.overlap-group4-1 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: var(--pearl-bush);
  border-radius: 12px;
  display: flex;
  height: 48px;
  margin-right: 38px;
  margin-top: 21px;
  min-width: 80px;
  padding: 12px 25px;
  z-index: 1;
}

.oui {
  letter-spacing: 0;
  line-height: normal;
  min-height: 20px;
}

.overlap-group2-1 {
  align-items: center;
  background-color: var(--pearl-bush);
  border-radius: 20px;
  display: flex;
  height: 148px;
  justify-content: flex-end;
  margin-right: 40.0px;
  margin-top: 21px;
  min-width: 300px;
  padding: 0 9px;
  z-index: 4;
}

.votre-potassium-est {
  letter-spacing: 0;
  line-height: normal;
  min-height: 120px;
  width: 281px;
}

.overlap-group-container {
  align-items: flex-start;
  display: flex;
  gap: 49px;
  margin-right: 1.0px;
  margin-top: 173px;
  min-width: 291px;
  z-index: 2;
}

.overlap-group-4 {
  background-color: var(--moon-mist);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  height: 71px;
  position: relative;
  width: 121px;
}

.overlap-group-item {
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 15px;
}

.overlap-group3-1 {
  align-items: flex-start;
  background-color: var(--moon-mist);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 71px;
  justify-content: flex-end;
  min-width: 121px;
  padding: 15px 24px;
}

.non {
  letter-spacing: 0;
  line-height: normal;
  min-height: 40px;
}

