.file-container {
  border: 2px solid #ccc; /* Bordure grise de 2 pixels */
  padding: 20px; /* Espace intérieur de 20 pixels */
  border-radius: 10px; /* Coins arrondis de 10 pixels */
  margin-bottom: 20px; /* Marge en bas de 20 pixels */
}

.file-card {
  margin: 10px;
}

.upload-section {
  margin-top: 20px;
}
