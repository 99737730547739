.choixu95profil2 {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 844px;
  padding: 72px 51px;
  width: 390px;
}

.place-2 {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  min-height: 60px;
  min-width: 158px;
}

.overlap-group-11 {
  border-radius: 20px;
  height: 174px;
  margin-top: 115px;
  position: relative;
  width: 287px;
}

.rectangle-2 {
  background-color: var(--yellow-green);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  cursor: pointer;
  height: 174px;
  left: 0;
  position: absolute;
  top: 0;
  width: 287px;
}

.je-suis-patient {
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 63px;
}

.overlap-group1-10 {
  border-radius: 20px;
  height: 174px;
  margin-top: 30px;
  position: relative;
  width: 287px;
}

.rectangle-3-2 {
  background-color: var(--keppel);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  cursor: pointer;
  height: 174px;
  left: 0;
  position: absolute;
  top: 0;
  width: 287px;
}

.je-suis-mdecin {
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 63px;
}

