.inter-medecin1 {
  align-items: flex-end;
  background-color: var(--seashell-peach);
  border: 100px none;
  /* display: flex; */
  flex-direction: column;
  gap: 50px;
  height: 900px;
  padding: 25px;
  margin-top: auto;
  width: 390px;
}

