.compte-patient {
  align-items: center;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 897px;
  padding: 20px 25px;
  width: 390px;
}

.flex-row-16 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  gap: 17px;
  height: 172px;
  min-width: 268px;
}

.ellipse-11-3 {
  cursor: pointer;
  height: 55px;
  object-fit: cover;
  width: 55px;
}

.nom-du-patient {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: normal;
  min-height: 120px;
  min-width: 196px;
  text-align: center;
}

.overlap-group2-3 {
  border-radius: 20px;
  height: 120px;
  margin-top: 55px;
  position: relative;
  width: 286px;
}

.rectangle-6 {
  background-color: var(--contessa);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  cursor: pointer;
  height: 120px;
  left: 0;
  position: absolute;
  top: 0;
  width: 286px;
}

.info-perso-1 {
  height: 100px;
  left: 93px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 100px;
}

.informations-personnelles {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  margin-top: 7px;
  min-height: 25px;
  min-width: 245px;
}

.flex-row-17 {
  align-items: flex-start;
  display: flex;
  gap: 46px;
  height: 347px;
  margin-top: 36px;
  min-width: 286px;
}

.flex-col-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 347px;
  width: 120px;
}

.overlap-group-9 {
  align-items: center;
  background-color: var(--keppel);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  min-width: 120px;
  padding: 0 12px;
}

.photo3-1-1 {
  height: 96px;
  width: 96px;
}

.fichiers-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 1.0px;
  margin-top: 9px;
  min-height: 25px;
  min-width: 71px;
}

.overlap-group3-3 {
  align-items: flex-end;
  background-color: var(--yellow-green);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  justify-content: flex-end;
  margin-top: 39px;
  min-width: 120px;
  padding: 8px;
}

.icon-search-1,
.traitement-1-1 {
  height: 100px;
  width: 100px;
}

.analyses-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 9px;
  min-height: 25px;
  min-width: 84px;
}

.overlap-group1-8 {
  align-items: flex-end;
  background-color: var(--romantic);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  justify-content: flex-end;
  min-width: 120px;
  padding: 8px 4px;
}

.traitements-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  margin-top: 9px;
  min-height: 25px;
  min-width: 113px;
}

.overlap-group4-3 {
  align-items: center;
  background-color: var(--del-rio);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 120px;
  margin-top: 39px;
  min-width: 120px;
  padding: 0 10px;
}

.evolution-1-1 {
  height: 96px;
  width: 100px;
}

.evolutions-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 9px;
  min-height: 25px;
  min-width: 96px;
}

