.inter-medecin2 {
  align-items: center;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 897px;
  width: 390px;
}

.rectangle-24 {
  background-color: var(--seashell-peach);
  border-radius: 2px;
  filter: blur(4px);;
  height: 273px;
  width: 390px;
  z-index: 5;
}

.rectangle-24-1 {
  background-color: var(--seashell-peach);
  border-radius: 2px;
  filter: blur(4px);;
  height: 273px;
  left: 0;
  position: fixed;
  top: 0;
  width: 390px;
  z-index: 6;
}

.ellipse-1-2 {
  cursor: pointer;
  height: 55px;
  left: 310px;
  object-fit: cover;
  position: fixed;
  top: 25px;
  width: 55px;
  z-index: 14;
}

.place-3 {
  left: 116px;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  top: 72px;
  z-index: 15;
}

.rectangle-20-1 {
  background-color: var(--del-rio);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  height: 38px;
  left: 35px;
  position: fixed;
  top: 173px;
  width: 320px;
  z-index: 11;
}

.icon-search-3 {
  height: 30px;
  left: 41px;
  position: fixed;
  top: 177px;
  width: 30px;
  z-index: 12;
}

.rechercher-1 {
  left: 76px;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 178px;
  z-index: 13;
}

.uniquement-mes-patients-1 {
  left: 87px;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 230px;
  z-index: 10;
}

.rectangle-24-2 {
  background-color: var(--seashell-peach);
  border: 1px solid;
  border-color: var(--black);
  cursor: pointer;
  height: 20px;
  left: 56px;
  position: fixed;
  top: 231px;
  width: 20px;
  z-index: 7;
}

.line-3 {
  height: 15px;
  left: 58px;
  object-fit: cover;
  position: fixed;
  top: 233px;
  width: 15px;
  z-index: 9;
}

.line-2 {
  height: 15px;
  left: 58px;
  object-fit: cover;
  position: fixed;
  top: 234px;
  width: 15px;
  z-index: 8;
}

.overlap-group1-12 {
  border-radius: 20px;
  height: 45px;
  margin-top: 5px;
  position: relative;
  width: 320px;
  z-index: 1;
}

.rectangle-1-1 {
  background-color: var(--pearl-bush);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  cursor: pointer;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 320px;
}

.franois-dupont-3,
.name-5,
.name-6,
.valrie-moreau-1 {
  left: 21px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 9px;
}

.icon-notifications-4 {
  height: 22px;
  left: 289px;
  object-fit: cover;
  position: absolute;
  top: 12px;
  width: 20px;
}

.overlap-group-14 {
  border-radius: 20px;
  height: 45px;
  margin-top: 25px;
  position: relative;
  width: 320px;
  z-index: 2;
}

.overlap-group2-6 {
  border-radius: 20px;
  height: 45px;
  margin-top: 25px;
  position: relative;
  width: 320px;
  z-index: 3;
}

.overlap-group3-6 {
  border-radius: 20px;
  height: 45px;
  margin-top: 25px;
  position: relative;
  width: 320px;
  z-index: 4;
}

.icon-notifications-5 {
  height: 22px;
  left: 289px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 20px;
}

