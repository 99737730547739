.liste-traitement1 {
  align-items: flex-start;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 891px;
  width: 390px;
}

.overlap-group-7 {
  background-color: var(--romantic);
  border-radius: 2px;
  height: 188px;
  position: relative;
  width: 390px;
}

.title-6 {
  left: 54px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 71px;
}

.ellipse-5-4 {
  cursor: pointer;
  height: 55px;
  left: 25px;
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 55px;
}

.flex-row-12 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  gap: 190px;
  margin-right: 11.0px;
  margin-top: 46px;
  min-width: 317px;
}

.kaleorid-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1px;
  min-height: 25px;
  min-width: 97px;
}

.icon-trash {
  height: 30px;
  object-fit: cover;
  width: 30px;
}

.address-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 13px;
  min-height: 25px;
}

.flex-row-13 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  gap: 198px;
  margin-right: 11.0px;
  margin-top: 51px;
  min-width: 317px;
}

.aranesp {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 1px;
  min-height: 25px;
  min-width: 89px;
}

.address-3 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 10px;
  min-height: 25px;
}

.flex-row-14 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  gap: 231px;
  margin-right: 11.0px;
  margin-top: 52px;
  min-width: 317px;
}

.cacit {
  letter-spacing: 0;
  line-height: normal;
  min-height: 25px;
  min-width: 56px;
}

.address-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 31px;
  margin-top: 9px;
  min-height: 25px;
}

.flex-row-15 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  gap: 201px;
  margin-right: 8.0px;
  margin-top: 48px;
  min-width: 320px;
}

.mimpara {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1px;
  min-height: 25px;
  min-width: 89px;
}

.overlap-group1-6 {
  align-self: flex-end;
  height: 69px;
  margin-right: 16px;
  margin-top: 81px;
  position: relative;
  width: 77px;
}

.historique-1 {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 49px;
}

.ellipse-11-2 {
  cursor: pointer;
  height: 50px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 50px;
}

