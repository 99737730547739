.choix-profil-3 {
  align-items: center;
  background-color: var(--seashell-peach);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 844px;
  padding: 18px 20px;
  width: 390px;
}

.overlap-group {
  height: 154px;
  margin-right: 46.0px;
  position: relative;
  width: 304px;
}

.mot-de-passe-oubli {
  color: var(--black);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  left: 46px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 54px;
}

.ellipse-11 {
  cursor: pointer;
  height: 55px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 55px;
}

.adresse-mail {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 28px;
  margin-top: 60px;
  min-height: 25px;
  min-width: 120px;
  text-align: center;
}

.rectangle-3 {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--black-2);
  height: 48px;
  margin-left: 1.0px;
  margin-top: 8px;
  width: 293px;
}

.overlap-group1 {
  align-items: flex-start;
  background-color: var(--pearl-bush);
  border-radius: 20px;
  box-shadow: 4px 4px 4px #00000040;
  display: flex;
  height: 45px;
  margin-left: 1.0px;
  margin-top: 396px;
  min-width: 295px;
  padding: 9px 109px;
}

.envoyer {
  letter-spacing: 0;
  line-height: normal;
  min-height: 25px;
  min-width: 77px;
  text-align: center;
}

